body {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  color: #B8B9BD;
  background-color: #121723;
  overflow-x: clip;
  overflow-y: scroll;
}

.App {
  height: 100%;
  width: 100%;
}

header {
  padding: 10px;
}

nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
}

nav ul li {
  margin-right: 20px;
}

nav ul li a {
  color: #E3E3E5;
  text-decoration: none;
}

main {
  padding: 20px;
}

section {
  margin-top: 20px;

}

h1 {
  font-size: 2.5rem;
}
#about {
  display: flex;
  justify-content: center;
  align-items: center;
}

.grid-container {
  display: grid;
  margin-left: 10%;
  margin-right: 10%;
  grid-template-columns: 1fr 2fr ;
  grid-gap: 25px;
  align-items: center;
  justify-items: center;
  width: 100%;
  padding-top: 40px;

}
/* mobile */
@media (max-width: 576px) {
  .grid-container {
    margin-left: 0;
    margin-right: 0;
    grid-template-columns: 1fr;
    grid-gap: 10px;
    padding: 60px 10px;

  }
}
/* tablet */
@media (max-width: 950px) {
  .grid-container {
    margin-left: 5%;
    margin-right: 5%;
    grid-gap: 10px;
    padding: 60px 10px;

  }
}

.grid-item {
  text-align: left;
}

/* mobile */
@media (max-width: 576px) {
  .grid-item {
    text-align: left;
    font-size: 16px;
  }
}

.grid-item-circle {
  border-radius: 50%;
  height: 300px;
  overflow: hidden;
  width: 300px;
}
/* mobile */
@media (max-width: 576px) {
  .grid-item-circle {
    height: 150px;
    width: 150px;
  }
}
/* tablet */
@media (max-width: 850px) {
  .grid-item-circle {
    height: 200px;
    width: 200px;
  }
}

.grid-item-circle img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
@media all and (min-width: 576px) {
  .desktopAboutDesc {display:block;}
  .mobileAboutDesc {display:none;}
}

@media all and (max-width: 576px) {
  .desktopAboutDesc {display:none;}
  .mobileAboutDesc {display:block;}
}

.btn-gray {
  background-color: #363A44;
  border-radius: 10px;
  color: #E3E3E5;
  display: inline-block;
  font-size: 1.2rem;
  margin: 5px 5px 5px 0;
  padding: 20px 20px;
  text-decoration: none;
  width: 30%;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.24);
}
.btn-gray:hover {
  background-color: #1E60FF;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.24);
}

/* tablet */
@media (max-width: 1330px) {
  .btn-gray {
    width: 90%;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.24);
  }
}
/* mobile */
@media (max-width: 576px) {
  .btn-gray {
    width: 100%;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.24);
  }
}

.btn-lime {
  background-color: #2cae46;
  border-radius: 10px;
  color: #E3E3E5;
  display: inline-block;
  font-size: 1.2rem;
  margin: 5px 5px 5px 0;
  padding: 20px 20px;
  text-decoration: none;
  width: 30%;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.24);
}
.btn-lime:hover {
  background-color: #1E60FF;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.24);
}
/* tablet */
@media (max-width: 1330px) {
  .btn-lime {
    width: 90%;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.24);
  }
}
/* mobile */
@media (max-width: 576px) {
  .btn-lime {
    width: 100%;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.24);
  }
}


.base-header {
  font-family: "Roboto", sans-serif;
  font-size: 40px;
  font-weight: bold;
  color: #E3E3E5;
}
/* mobile */
@media (max-width: 576px) {
  .base-header {
    font-family: "Roboto", sans-serif;
    font-size: 30px;
    font-weight: bold;
    color: #E3E3E5;
  }
}
/* Projects  CSS*/
.projects {
  padding: 100px 0;
  background-color: #121723;
  font-size: 18px;
  font-family: Roboto, sans-serif;
  text-align: center;
}
.projects-icon {
  border-radius: 50%;
  height: 50px;
  overflow: hidden;
  width: 50px;
}

.projects-header {
  text-align: center;
  margin-bottom: 30px;
  font-size: 36px;
  font-weight: bold;
  color: #E3E3E5;
}
/* mobile */
@media (max-width: 420px) {
  .projects-header {
    font-size: 20px;
  }
}
/* Tablet */
@media (max-width: 1100px) {
  .projects-header {
    font-size: 28px;
  }
}

.projects-description {
  text-align: center;
  margin-bottom: 50px;
  color: #B8B9BD;
  white-space: pre-line;
}

.projects-grid {
  margin-left: 10%;
  margin-right: 10%;
  justify-content: center;
  align-content: center;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr)); /* change this to make project cards larger */
  grid-gap: 25px;
}
/* Tablet */
@media (max-width: 1100px) {
  .projects-grid {
    font-size: 16px;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr)); /* change this to make project cards larger */
  }
}
/* Landscape */
@media (max-width: 950px) {
  .projects-grid {
    font-size: 15px;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); /* change this to make project cards larger */
  }
}
/* mobile */
@media (max-width: 570px) {
  .projects-grid {
    font-size: 14px;
    grid-template-columns: repeat(auto-fit, minmax(320px, 1fr)); /* change this to make project cards larger */
  }
}

.project-card {
  position: relative;
  border-radius: 5px;
}

.project-card img {
  display: block;
  width: 100%;
  height: auto;
  transition: transform 0.3s ease-in-out;
}

.project-card .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.project-card:hover .overlay {
  opacity: 1;
}

.project-card .overlay-content {
  position: absolute;
  top: 90%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #E3E3E5;
}

.project-card .overlay-button {
  position: absolute;
  top: 90%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #2cae46;
  font-size: 1rem;
  padding: 10px 20px;
  text-decoration: none;
  border-radius: 5px;
  color: #E3E3E5;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.24);

}
.overlay-button:hover {
  background-color: #1E60FF;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.24);
}
/* Projects Expanded CSS */
@media all and (min-width: 950px) {
  .desktopProject-Expanded {display: block}
  .mobileProject-Expanded {display:none;}
}

@media all and (max-width: 950px) {
  .desktopProject-Expanded {display:none;}
  .mobileProject-Expanded {display:block;}
}
.project-expanded {
  display: grid;
  align-items: start;
  grid-template-columns: repeat(2, 1fr);


}
/* mobile */
@media (max-width: 950px) {
  .project-expanded {
    display: inline-block;
    width: 100%;
  }
}

.projects-description-grid-1 {
  display: grid;
  justify-content: center;
  align-content: space-between;
  align-items: start;
  justify-items: center;
  margin-top: -40px;
  border-right: #E3E3E5 solid 8px;

  /*border-top: #E3E3E5 solid 8px;*/
}
/* mobile */
@media (max-width: 950px) {
  .projects-description-grid-1 {
    display: grid;
    justify-content: center;
    align-content: space-between;
    align-items: center;
    justify-items: center;
    margin-top: 0;
    border-right: #E3E3E5 solid 0;
    border-left: #E3E3E5 solid 8px;

    /*border-top: #E3E3E5 solid 8px;*/
  }
}
.projects-description-grid-2 {
  display: grid;
  justify-content: center;
  align-content: center;
  align-items: center;
  justify-items: center;
  position: sticky;
  height: 100vh;
  top: 0;

}
/* mobile */
@media (max-width: 950px) {
  .projects-description-grid-2 {
    display: grid;
    justify-content: center;
    align-content: center;
    align-items: center;
    justify-items: center;
    position: relative;
    padding-bottom: 20px;
    height: 50%;
    top: 0;
    box-sizing: border-box;
  }
}
/*!* mobile *!*/
/*@media (max-width: 576px) {*/
/*  .project-expanded {*/
/*    margin-left: 0;*/
/*    margin-right: 0;*/
/*    grid-template-rows: repeat(auto-fit, minmax(500px, 1fr));*/
/*    grid-gap: 25px;*/
/*    padding: 60px 30px;*/

/*  }*/
/*}*/

.image-slideshow {
  position: relative;
  border-radius: 5px;
  height: auto;
  max-height: 800px;
  max-width: 700px;

}
/* Small screens */
@media (max-width: 950px) {
  .image-slideshow {
    position: relative;
    border-radius: 5px;
    height: auto;
    max-width: 400px;

  }
}

/* mobile */
@media (max-width: 450px) {
  .image-slideshow {
    position: relative;
    border-radius: 5px;
    height: auto;
    max-width: 350px;

  }
}

/* mobile tiny */
@media (max-width: 300px) {
  .image-slideshow {
    position: relative;
    border-radius: 5px;
    height: auto;
    max-width: 250px;

  }
}

.image-slideshow img {
  width: 100%;
  height: auto;
  object-fit: cover;
  transition: transform 0.3s ease-in-out;
}

.image-slideshow .prev,
.image-slideshow .next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 3rem;
  cursor: pointer;
  transition: opacity 0.3s ease-in-out;
}
/* mobile */
@media (max-width: 950px) {
  .image-slideshow .prev,
  .image-slideshow .next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 2rem;
    cursor: pointer;
    transition: opacity 0.3s ease-in-out;
  }
}

.image-slideshow .prev:hover,
.image-slideshow .next:hover {
  opacity: 0.8;
}

.image-slideshow .prev {
  left: 1rem;
}

.image-slideshow .next {
  right: 1rem;
}

.text-description {
  width: 100%;
  font-size: 18px;
  text-align: left;
  white-space: pre-line;
  border-bottom: #E3E3E5 solid 8px;
  overflow-wrap: break-word;

}
.text-description-no-border {
  width: 100%;
  font-size: 18px;
  text-align: left;
  white-space: pre-line;
  overflow-wrap: break-word;
}
/* mobile */
@media (max-width: 800px) {
  .text-description {
    font-size: 16px;
  }
}
/* mobile */
@media (max-width: 350px) {
  .text-description {
    font-size: 15px;
  }
}
/* mobile */
@media (max-width: 800px) {
  .text-description-no-border {
    font-size: 16px;
  }
}
/* mobile */
@media (max-width: 350px) {
  .text-description-no-border {
    font-size: 15px;
  }
}

.text-description-header {
  margin-left: 10px;
  color: #E3E3E5;
  font-size: 23px;
  text-align: left;
  white-space: pre-line;
}
/* mobile */
@media (max-width: 800px) {
  .text-description-header {
    margin-left: 10%;
    margin-right: 10%;
    color: #E3E3E5;
    font-size: 20px;
    text-align: left;
    white-space: pre-line;
  }
}
/* mobile tiny*/
@media (max-width: 340px) {
  .text-description-header {
    margin-left: 15%;
    margin-right: 15%;
    color: #E3E3E5;
    font-size: 20px;
    text-align: left;
    white-space: pre-line;
  }
}

.text-description p {
  margin-left: 10px;
  margin-right: 10px;
}
/* mobile */
@media (max-width: 800px) {
  .text-description p {
    margin-left: 10%;
    margin-right: 10%;
  }
}
/* mobile tiny*/
@media (max-width: 340px) {
  .text-description p {
    margin-left: 15%;
    margin-right: 15%;
  }
}
.text-description-no-border p {
  margin-left: 10px;
  margin-right: 10px;
}
/* mobile */
@media (max-width: 800px) {
  .text-description-no-border p {
    margin-left: 10%;
    margin-right: 10%;
  }
}
/* mobile tiny*/
@media (max-width: 340px) {
  .text-description-no-border p {
    margin-left: 15%;
    margin-right: 15%;
  }
}
.text-description a {
  color: #B8B9BD;
  text-overflow: clip;
}
.text-description-no-border a {
  color: #B8B9BD;
}

/*Navbar CSS*/
.navbar {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  background-color: #0D111A;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  padding-right: 30px;
  border-bottom: 1px solid #000;
  z-index: 10;
}

.navbar-header {
  padding: 4px 4px 4px 8px;
  display: flex;
  align-items: center;
}

.navbar-header-text {
  font-size: 24px;
  color: #E3E3E5;
  margin-right: 10px;
}

.navbar-header-text-mobile {
  font-size: 20px;
  color: #E3E3E5;
  padding: 20px;
}

.navbar-divider {
  width: 2px;
  height: 100%;
  background-color: #000;
  margin-right: 10px;
}

.navbar-links {
  display: flex;
  text-align: left;
}

.navbar-link {
  font-size: 18px;
  color: #B8B9BD;
  text-decoration: none;
  margin-right: 20px;
  padding: 8px 12px;
}

.navbar-link.active {
  border-bottom: 5px solid #B8B9BD;
  padding-bottom: 6px; /* Increase the padding on the bottom to make room for the wider border */
  margin-bottom: -2px; /* Add a negative margin to the bottom border to make it slightly wider */
}

.navbar-link:hover {
  text-decoration: underline;
}

.navbar-mobile {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  background-color: #10161d;
  z-index: 100;
}

.hamburger-btn {
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
  padding: 10px;
  position: relative;
}

.hamburger-icon,
.hamburger-icon::before,
.hamburger-icon::after {
  background-color: #E3E3E5;
  content: "";
  display: block;
  height: 3px;
  position: absolute;
  width: 24px;
}

.hamburger-icon {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.hamburger-icon::before {
  top: -8px;
}

.hamburger-icon::after {
  bottom: -8px;
}

.side-nav {
  background-color: #10161d;
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  transform: translateX(-100%);
  transition: transform 0.3s ease-in-out;
  width: 250px;
  z-index: 1;
}

.side-nav.open {
  transform: translateX(0);
}

.side-nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.side-nav li {
  margin-top: 5px;
  padding: 10px;
}

.side-nav li a {
  color: #E3E3E5;
  display: block;
  font-size: 20px;
  text-decoration: none;
  transition: color 0.3s ease-in-out;
}



@media all and (min-width: 950px) {
  .desktopNav {display:block;}
  .mobileNav {display:none;}
}

@media all and (max-width: 950px) {
  .desktopNav {display:none;}
  .mobileNav {display:block;}
}
/* form css */
.contact-form-container {
  width: 100%;
  justify-content: center;
  display: inline-flex;
}

form {
  width: 100%;
  margin-left: 10%;
  margin-right: 10%;
  background-color: #0D111A;
  padding: 20px;
  border-radius: 5px;
  border: 6px black;
}

.form-group {
  margin-bottom: 20px;
}

label {
  display: block;
  margin-bottom: 5px;
}

input[type='text'],
textarea {
  width: 100%;
  display: inline-flex;
  word-wrap: break-word;
  height: fit-content;
  hyphens: manual;
  overflow-wrap: break-word;
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
  border: none;
  color: #E3E3E5;
  border-bottom: 2px solid #B8B9BD;
  background-color: transparent;
}

input[type='text']:focus,
textarea:focus {
  border-bottom: 2px solid #121723;
}

.error {
  color: red;
  font-size: 14px;
  margin-top: 5px;
  display: block;
}

button[type='submit'] {
  background-color: #05070C;
  font-size: 18px;
  color: #E3E3E5;
  padding: 20px 32px;
  margin-left: 35%;
  border: none;
  width: 30%;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.24);
}
/* mobile */
@media (max-width: 700px) {
  button[type='submit'] {
    background-color: #05070C;
    font-size: 18px;
    color: #E3E3E5;
    padding: 20px 32px;
    border: none;
    margin-left: 25%;
    width: 50%;
    border-radius: 10px;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.24);
  }
}
button[type='submit']:hover {
  background-color: #1E60FF;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.24);
}
/* Skills CSS */
.skills-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 15px;
  margin-bottom: 15px;
}

.skill-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 300px;
  margin-bottom: 10px;
}

.skill-name {
  font-size: 18px;
  margin-right: 20px;
}

.skill-rating {
  display: flex;
}

.star-icon {
  width: 20px;
  height: 20px;
  background-color: transparent;
  color: #2cae46;
  margin-right: 5px;

}
.star-icon.filled {
  filter: brightness(0) invert(1);
}

.star-icon.empty {
  color: transparent;
}

/* Socials CSS */
.socials-header {
  text-align: center;
}
.socials {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
  justify-items: center;
  padding: 20px;
}
/* mobile */
@media (max-width: 700px) {
  .socials {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(64px, 1fr));
    grid-gap: 20px;
    padding: 20px;
    justify-content: center;
    align-items: center;
    justify-items: center;
    margin: 20px 0;
  }
}

.socials a {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 64px;
  height: 64px;
  margin: 0 10px;
  background-color: #10161d;
  border-radius: 25%;
  text-decoration: none;
}

.socials a:hover {
  background-color: #1E60FF;
  color: #10161d;
}

.socials i {
  font-size: 20px;
}
.socials_large {
  display: grid;
  margin-left: 5%;
  margin-right: 5%;
  grid-gap: 15px;
  min-width: 340px;
  max-width: 400px;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  justify-items: center;
  align-items: center;
}
/* tablet */
@media (max-width: 700px) {
  .socials_large {
    display: grid;
    margin-left: 5%;
    margin-right: 5%;
    grid-gap: 15px;
    min-width: 200px;
    max-width: 300px;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    justify-items: center;
  }
}


.socials_large img {

  max-width: 200px;
  width: 100%;
  height: auto;
}
@media (max-width: 450px) {
  .socials_large img {
    max-width: 150px;
    width: 100%;
    height: auto;
  }
}
.socials_large a:hover {
  background-color: #1E60FF;
  color: #10161d;
  border-radius: 5%;
}
